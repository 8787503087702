var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.created ? _c('DataTable', {
    attrs: {
      "options": _vm.options
    }
  }) : _vm._e(), _c('vx-dialog', {
    attrs: {
      "title": "Select component schema",
      "width": "400",
      "actions": ""
    },
    on: {
      "resolve": function resolve($event) {
        return _vm.$add('component', {
          id: _vm.schemaId
        });
      }
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "items": _vm.schemas,
      "item-text": "name",
      "item-value": "_id",
      "name": "Component schema",
      "label": "Component schema"
    },
    model: {
      value: _vm.schemaId,
      callback: function callback($$v) {
        _vm.schemaId = $$v;
      },
      expression: "schemaId"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }