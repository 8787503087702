<template>
  <div>
    <DataTable v-if="created" :options="options" />

    <vx-dialog
      v-model="dialog"
      title="Select component schema"
      width="400"
      actions
      @resolve="$add('component', { id: schemaId })"
    >
      <vx-input
        v-model="schemaId"
        type="autocomplete"
        :items="schemas"
        item-text="name"
        item-value="_id"
        name="Component schema"
        label="Component schema"
      />
    </vx-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { ComponentSchemaService } from '@tutti/services';

export default {
  data() {
    return {
      created: false,
      dialog: false,
      schemas: [],
      schemaId: this.$route.params.id,
      options: {
        action: {
          showDuplicate: true,
          hideView: true,
        },
        actions: [
          {
            text: 'Enable',
            body: { isActive: true },
          },
          {
            text: 'Disable',
            body: { isActive: false },
          },
          {
            text: 'Delete',
            body: { isDeleted: true },
            message: 'Delete',
          },
        ],
        addNew: this.addNew,
        // showHeader: {
        //   publishedBy: true,
        //   publishedAt: true,
        // },
      },
    };
  },
  async created() {
    await this.getSchemas();

    this.options.headers = [
      { text: 'Name', value: 'name' },
      {
        text: 'Schema',
        value: 'schemaId',
        component: { name: 'dtView', props: { name: 'component-schema', permission: 'cms_component_schema' } },
        filter: {
          type: 'autocomplete',
          items: this.schemas,
          itemText: 'name',
          itemValue: '_id',
        },
      },
      {
        text: 'Enabled',
        value: 'isActive',
        width: '50px',
        align: 'center',
        component: { name: 'dtCheckbox', props: { action: true } },
      },
    ];

    this.created = true;
  },
  methods: {
    addNew() {
      if (this.schemaId) {
        this.$add('component', { id: this.schemaId });
      } else {
        this.dialog = true;
      }
    },
    async getSchemas() {
      const response = await ComponentSchemaService.getAll();
      if (response && response.data.data.length) {
        this.schemas = _.sortBy(response.data.data, ['obj', 'name']);
      }
    },
  },
};
</script>
